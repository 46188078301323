import { Link } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";

import Modal from "react-bootstrap/Modal";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import { Header } from "../components/Header";
import Footer from "../components/Footer";
import { ProductDeal } from "../components/ProductDeal";
import { DealFilter } from "../components/DealFilter";
import { PercentFilter } from "../components/PercentFilter";

import blackCloseIcon from "../assets/images/icons/black-cross-icon.svg";
import whatsAppIcon from "../assets/images/icons/whatsapp-green-icon.svg";
import { BannerSlider } from "../components/BannerSlider";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import downTriangleIcon from "../assets/images/icons/down-triangle-icon.svg";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import availableProductImg1 from "../assets/images/icons/available-products-img-1.png";
import productOfferingImg from "../assets/images/product-offering-img-bg.png";
import Moment from "react-moment";
import LoadingSpinner from "../components/loading";
import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import { getCookie, getTokens, setCookie } from "../helpers/helpers";
import { useNavigate } from 'react-router-dom';
import 'moment-timezone';
import { ProgressBar } from "react-bootstrap";
function Home() {
  const [selectedPercent, setSelectedPercent] = useState(['', '']);
  let navigate = useNavigate();
  const [bannerData, setBannerData] = useState();
  const [tokens, setTokenData] = useState({});
  const [datalen, setDataLen] = useState();
  const [tnccontent, setTncContent] = useState("");
  const [tncheader, setTncHeader] = useState("");
  const [tncshow, setTncShow] = useState(false);
  const [userUpiId, setUserUpiId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [policyBtn, setPolicyBtn] = useState(true);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);

  const sortClose = () => setShowSort(false);
  const sortOpen = () => setShowSort(true);

  const [showCondition, setShowCondition] = useState(true);
  const ConditionClose = () => setShowCondition(false);

  const [selectedfilter, setSelectedFilter] = useState("");

  const [numberCheck, setNumberCheck] = useState("");
  const [number, setNumber] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [product, setProduct] = useState("");

  const [mobile, setMobile] = useState(window.innerWidth <= 700);

  const [distributer, setDistributer] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [reward, setReward] = useState([]);
  const [month, setMonth] = useState([]);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getProductDeal();
  }, [selectedPercent]);

  useEffect(() => {
    console.log(companies, "owais");
    console.log(distributer, "owais");
    console.log(reward, "owais");
  }, [distributer, companies, reward]);

  const checkWhatsapp = (e) => {
    console.log(e.target.checked, "e.target.value");
    setNumberCheck(e.target.checked);
  };
  useEffect(() => {
    setPolicyBtn(!numberCheck)
  }, [numberCheck])
  const sortProducts = (e) => {
    setProduct(
      product.sort(
        (a, b) => b.discount.match(/\d+/)[0] - a.discount.match(/\d+/)[0]
      )
    );
    sortClose();
    setSelectedFilter(e.target.value);
  };
  const handleWhatsapp = (e) => {
    setNumber(e.target.value);
    console.log(e.target.value.length, "e.target.value");
    console.log(e.target.checked, "e.target.value");
    if (e.target.value.length >= 10) {
      setPolicyBtn(true);
    } else {
      setPolicyBtn(false);
    }
  };
  const distributorsFilter = (e, value) => {
    const newDistributer = [...distributer];
    const index = newDistributer.indexOf(value);
    if (index === -1) {
      newDistributer.push(value);
    } else {
      newDistributer.splice(index, 1);
    }
    setDistributer(newDistributer);
  };
  const companiesFilter = (e, value) => {
    const newCompanies = [...companies];
    const index = newCompanies.indexOf(value);
    if (index === -1) {
      newCompanies.push(value);
    } else {
      newCompanies.splice(index, 1);
    }
    setCompanies(newCompanies);
  };
  const rewardFilter = (e, value) => {
    const newReward = [...reward];
    const index = newReward.indexOf(value);
    if (index === -1) {
      newReward.push(value);
    } else {
      newReward.splice(index, 1);
    }
    setReward(newReward);
  };

  const monthFilter = (e, value) => {
    const newMonth = [...month];
    const index = newMonth.indexOf(value);
    if (index === -1) {
      newMonth.push(value);
    } else {
      newMonth.splice(index, 1);
    }
    setMonth(newMonth);
  };

  const productFilter = () => {
    getProductDeal()
    filterClose();
  };

  const accept = async (e) => {
    // e.preventDefault();
    ConditionClose();
    // `${BASE_URL}/web-sdk/api/schemes/product/deals?company_ids=CP56089&distributor_ids=12323455656&reward_types=upi_bank_account,token
    console.log("CALL ACCEPT");
    try {
      let ep = `${BASE_URL}/web-sdk/api/guidelines/tnc/accept?theme=#c12424`;
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf("?")));
      const response = await fetch(ep, {
        method: "PUT",
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          whatsapp_contact: number,
          upi_id: userUpiId,
        }),
      });
      const json = await response.json();
      console.log(json);
      setCookie("accept", "accepted");
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {

  }, []);
  const homepagedata = async () => {
    try {
      let ep = `${BASE_URL}/web-sdk/api/home?theme=#c12424`;
      const response = await fetch(ep, {
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
        },
      });
      const json = await response.json();

      setBannerData(json);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const temp = getTokens()
    console.log({ temp })
    setTokenData(temp)
  }, []);
  useEffect(() => {
    homepagedata();
  }, []);

  const getProductDeal = async () => {
    setIsLoading(true);
    // `${BASE_URL}/web-sdk/api/schemes/product/deals?company_ids=CP56089&distributor_ids=12323455656&reward_types=upi_bank_account,token

    try {
      let ep = `${BASE_URL}/web-sdk/api/schemes/all/deals?company_ids=${companies.join(
        ","
      )}&distributor_ids=${distributer.join(",")}&reward_types=${reward.join(
        ","
      )}&months=${month.join(
        ","
      )}&from_percent=${selectedPercent[0]}&to_percent=${selectedPercent[1]
        }&theme=#c12424`;
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf("?")));
      // let endpoint=`${BASE_URL}/web-sdk/api/schemes/product/deals?company_ids=${companies.join(",")}&distributor_ids=${distributer.join(",")}&reward_types=${reward.join(",")}&months=${month.join(",")}&sortbyfilter=${selectedfilter.join(",")}&percentfilter=${selectedPercent.join(",")}`

      const response = await fetch(ep, {
        // const response = await fetch(`${BASE_URL}/web-sdk/api/schemes/product/deals`, {
        method: "GET",
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
        },
      });
      const json = await response.json();
      setFilterBy(json);
      console.log(filterBy, "ok");

      setProduct(json.deals);
      console.log(json?.deals[0]?.data.length, "my data");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    setMobile(window.mobileCheck);
  }, []);
  //   useEffect(() => {
  //     setDataLen( filterBy[0]?.data[0].length)
  //     console.log("asdfadsf",filterBy?.data.length)
  //   }, [filterBy]);
  const handleConsent = (val) => {
    if (val === "tnc") {
      setTncContent(bannerData?.data?.tnc);
      setTncHeader("Terms & Conditions");
    } else {
      setTncHeader("Privacy Policy");
      setTncContent(bannerData?.data?.policy);
    }
    setTncShow(true);
    setShowCondition(true);
  };
  return (
    <>
      <Header pageTitle="Cashback Deals" />
      <BannerSlider data={bannerData?.data?.banners} />

      <section className="company-deals">
        <div className="container">
          <ProductDeal data={bannerData?.data?.categories} />
        </div>
      </section>

      <section className="available-products">
        <div className="container">
          <div className="filter-wrapper">
            <DealFilter />
            <div className="product-filter">
              <Link
                to="#"
                className="btn-style medium transparent gray blue-bg icon"
                onClick={filterOpen}
              >
                Filter by
                <img src={`${filterByIcon}`} alt="Product" />
              </Link>
              {/* <Link to="#" className="btn-style medium transparent gray blue-bg icon" onClick={sortOpen}>Sort
                                by : <span>Cashback (high - low)</span>
                                <img src={`${downTriangleIcon}`} alt="Product"/>
                            </Link> */}
            </div>
            <PercentFilter selectedPercent={selectedPercent} setSelectedPercent={setSelectedPercent} />
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="product-wrapper">
              {product && 
                product.map((item, i) => (
                  <div className="available-products-card" key={i}>
                    <div className="row">
                      {item?.data?.length > 1 ? (
                        <div className="col-12">
                          <div
                            onClick={() => navigate('/schema/' + item.scheme_id)}
                            className="hvcombo-products-card align-right"
                            key={i}
                          >
                            <div
                              style={{
                                position: "relative",
                                textAlign: "center",
                                padding: "0 0 15px 0",
                              }}
                            >
                              <p className="p medium">{item.scheme_name}</p>
                            </div>
                            <div
                              style={{ position: "absolute", right: 2, top: 2 }}
                            >
                              {item.discount && (
                                <div className="offer">
                                  {item.discount} <br />
                                  Off
                                </div>
                              )}
                            </div>

                            <div
                              className="comb-img"
                              style={{ overflowX: "auto"}}
                            >
                              <div className="img-wrapper-container">
                                {item &&
                                  item.data.map((img, i) => (
                                    <div
                                      className="img-wrapper"
                                      style={{ marginLeft: "10px", marginRight: "10px" }}
                                      key={i}
                                    >
                                      <img src={`${img.logo}`} alt="Product" />
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="content-wrapper">
                              <span>{item.message}</span>

                              <h3>
                                Valid till{" "}
                                <Moment
                                  format="D MMM YYYY"
                                  withTitle
                                  tz="America/New_York"
                                  date={item.ends_at}
                                />
                              </h3>
                              <div className="m-2">
                              <ProgressBar min={0} max={item.cashback_total} now={item.cashback_received} label={<><div>₹{item.cashback_received}</div></>} />
                              </div>
                              <a

                                href={mobile ? MOBILE_URL : WEB_URL}

                                className="btn-style medium"
                                target={mobile ? "_blank" : "_blank"}
                              >
                                ORDER NOW
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-8">
                          <div onClick={() => navigate('/schema/' + item.scheme_id)} className="product-detail">
                            <Link
                              className="img-wrapper"
                            >
                              <img
                                style={{maxWidth:"100%",maxHeight:"100%"}}
                                src={`${item?.data[0]?.logo}`}
                                alt="Product"
                              />
                            </Link>
                            <div className="content-wrapper">
                              <h3>{item?.data[0]?.name}</h3>
                              <span>{item.message}</span>
                              <strong>
                                Valid till{" "}
                                <Moment
                                  tz="America/New_York"
                                  format="D MMM YYYY"
                                  withTitle
                                  date={item.ends_at}
                                />
                              </strong>
                            </div>
                          </div>
                          <div className="m-2">
                          <ProgressBar min={0} max={item.cashback_total} now={item.cashback_received} label={<><div>₹{item.cashback_received}</div></>} />
                          </div>
                        </div>
                      )}

                      {item?.data?.length > 1 ? (
                        ""
                      ) : (
                        // <div className="col-4">
                        //   <div className="product-offer">
                        //     {/*<img src={`${productOfferingImg}`} alt="Product"/>*/}
                        //     <div className="offer">
                        //       {item.discount} <br />
                        //       Off
                        //     </div>
                        //     <Link
                        //       className="btn-style medium"
                        //       to={
                        //         mobile === true

                        //           ? "https://ops.pharmarack.com/Retailers/OrderCart/Create?OrderViaProductSearch=1"
                        //           : "https://ops.pharmarack.com/Retailers/OrderCart/Create?OrderViaProductSearch=1"

                        //       }
                        //     >
                        //       ORDER NOW
                        //     </Link>
                        //     {/* <a href="#" className="btn-style medium">ORDER NOW</a> */}
                        //   </div>
                        // </div>
                        <div className="col-4">
                          <div className="product-offer">
                            {/*<img src={`${productOfferingImg}`} alt="Product"/>*/}
                            <div className="offer">
                              {item.discount} <br />
                              Off
                            </div>
                            <a

                              href={mobile ? MOBILE_URL : WEB_URL}

                              className="btn-style medium"
                              target={mobile ? "_blank" : "_blank"}
                            >
                              ORDER NOW
                            </a>
                            {/* <a href="#" className="btn-style medium">ORDER NOW</a> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {/*<AvailableProduct/>*/}
            </div>
          )}
        </div>
      </section>

      <Footer />
      {bannerData?.data?.tnc_accepted === false ? (
        <div>
          <Modal show={tncshow} centered className="condition-popup">
            <Modal.Header>
              <div className="top-header">
                <h3>{tncheader}</h3>
              </div>
            </Modal.Header>

            <Modal.Body>
              <form>
                <div className="btn-wrapper text-center">
                  <div style={{ padding: "10px 0" }}>{tnccontent}</div>
                  {/*<input type="submit" onClick={(e) => {accept(e)}} value="ACCEPT" className={`btn-style ${!policyBtn ? "disabled" : ""}`} />*/}
                  <Link
                    style={{marginBottom : "10px"}}
                    className={`btn-style `}
                    onClick={() => {
                      setTncShow(false);
                    }}
                  >
                    CLOSE
                  </Link>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal show={showCondition} centered className="condition-popup">
            <Modal.Header>
              <div className="top-header">
                {/* <h3>Terms & Conditions</h3> */}
                <h3>Claim Your ₹11 Instant Cashback</h3>
                {/* <Link to="#" className="close-popup" onClick={ConditionClose}>
                  <img src={`${blackCloseIcon}`} alt="Icon" />
                </Link> */}
              </div>
            </Modal.Header>

            <Modal.Body>
              <form>
                <div className="condition-body-wrapper">
                    <div style={{width: "100%", marginBotton: "10px", paddingBottom: "10px"}}>
                    <p style={{ marginBotton: "10px", paddingBottom: "10px"}}>Enter Your UPI ID to participate in Instant Cashback Program.</p>
                    <input
                      onChange={(e) => setUserUpiId(e.target.value)}
                      type="text"
                      className="form-control pen-icon"
                      placeholder="Enter UPI ID"
                      value={userUpiId}
                      required
                    />
                    </div>
                  <div className="body-top">
                    {/* <label htmlFor="whatsapp-number">
                      <p>
                        Receive order and account related queries on
                        <br />
                        WhatsApp
                        <img src={`${whatsAppIcon}`} alt="whatsapp" />
                      </p>
                    </label>
                    <input
                      type="checkbox"
                      id="whatsapp-number"
                      onChange={checkWhatsapp}
                      required
                    /> */}
                  </div>

                  <div className="form">
                    {!numberCheck ? "" : (
                      <div className="form-group">
                        <input
                          type="text"
                          // onChange={(e) => setNumber(e.target.value)}
                          onChange={(e) => handleWhatsapp(e)}
                          className="form-control pen-icon"
                          placeholder="Enter Phone Number"
                          required
                        />
                      </div>
                    )}
                  </div>

                </div>
                <div className="btn-wrapper text-center">
                  {/*<input type="submit" onClick={(e) => {accept(e)}} value="ACCEPT" className={`btn-style ${!policyBtn ? "disabled" : ""}`} />*/}
                  <Link
                    to="#"
                    onClick={(e) => {
                      userUpiId.length > 0 ? accept(e) : console.log("upi not added.");
                    }}
                    value="ACCEPT"
                    className={`btn-style ${!policyBtn ? "disabled" : ""}`}
                  >
                    ACCEPT
                  </Link>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        ""
      )}
      <Modal
        show={showFilter}
        centered
        onHide={filterClose}
        className="filter-popup"
      >
        <Modal.Header>
          <a
            href="#"
            className="btn-style black medium transparent text icon"
            onClick={filterOpen}
          >
            Filter by
            <img src={`${filterByIcon}`} alt="Product" />
          </a>
          <a href="#" className="close-popup" onClick={filterClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-body-wrapper">
            <Tab.Container defaultActiveKey="first">
              <div className="form">
                <div className="filter-tabs-wrapper">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Company</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Distributor</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Reward</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Months</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {filterBy &&
                        filterBy?.filters?.companies?.map((item, i) => (
                          <div className="form-group checkbox" key={i}>
                            <input
                              type="checkbox"
                              checked={companies.includes(item.company_id)}
                              onChange={(e) =>
                                companiesFilter(e, item.company_id)
                              }
                              id={item.company_id}
                            />
                            <label htmlFor={item.company_id}>
                              {item.company_name}
                            </label>
                          </div>
                        ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {filterBy &&
                        filterBy?.filters?.distributors?.map((item, i) => (
                          <div className="form-group checkbox" key={i}>
                            <input
                              type="checkbox"
                              checked={distributer.includes(
                                item.distributor_id
                              )}
                              onChange={(e) =>
                                distributorsFilter(e, item.distributor_id)
                              }
                              id={item.distributor_id}
                            />
                            <label htmlFor={item.distributor_id}>
                              {item.distributor_name}
                            </label>
                          </div>
                        ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      {filterBy &&
                        filterBy?.filters?.reward_types?.map((item, i) => (
                          <div className="form-group checkbox" key={i}>
                            <input
                              type="checkbox"
                              checked={reward.includes(item.code)}
                              onChange={(e) => rewardFilter(e, item.code)}
                              id={item.code}
                            />
                            <label htmlFor={item.code}>{item.name}</label>
                          </div>
                        ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      {filterBy &&
                        filterBy?.filters?.months?.map((item, i) => (
                          <div className="form-group checkbox" key={i}>
                            <input
                              type="checkbox"
                              checked={month.includes(item.code)}
                              onChange={(e) => monthFilter(e, item.code)}
                              id={item.code}
                            />
                            <label htmlFor={item.code}>{item.name}</label>
                          </div>
                        ))}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a href="#" className="btn-style" onClick={productFilter}>
            Apply
          </a>
        </Modal.Footer>
      </Modal>
      {/* 
            <Modal show={showSort} centered onHide={sortClose} className="sort-popup">
                <Modal.Header>
                    <a href="#" className="btn-style black medium transparent text icon">Sort By</a>
                    <a href="#" className="close-popup" onClick={sortClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <div className="sort-body-wrapper">
                        <div className="sort-options">
                            <button style={{marginBottom:"20px"}} href="#" className="btn-style full transparent" value="Frequently Ordered" onClick={sortProducts}>Frequently Ordered</button>
                            <button style={{marginBottom:"20px"}}href="#" className="btn-style full transparent active" value ="Cashback : High to Low" onClick={sortProducts}>Cashback : High to Low</button>
                            <button style={{marginBottom:"20px"}}href="#" className="btn-style full transparent" value="PTR : High to Low" onClick={sortProducts}>PTR : High to Low</button>
                            <button href="#" className="btn-style full transparent" value = "PTR : Low to High" onClick={sortProducts}>PTR : Low to High</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
    </>
  );
}

export default Home;
